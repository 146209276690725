// import { NavLink } from "./NavLink"
import { Logo } from "./Logo"
import { Button } from "./Button"
// import { useState } from "react"
// import { Drawer } from "antd"
// import { CloseOutlined, MenuOutlined } from "@ant-design/icons"

const Header = () => {
  // const [open, setOpen] = useState(false);
  // const showDrawer = () => {
  //   setOpen(true);
  // };

  // const onClose = () => {
  //   setOpen(false);
  // };
  return (
    <header className="w-full text-white py-4 px-6 relative">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <Logo />
        {/* <nav className=" flex items-center space-x-4 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-md:hidden">
          <NavLink href={"https://www.baidu.com"}  >{t('header.product')}</NavLink>
          <NavLink href={""} >{t('header.ourStory')}</NavLink>
          <NavLink href={""} >{t('header.careers')}</NavLink>
        </nav> */}
        <div className="flex items-center ">
          {/* <MenuOutlined className="mr-5 text-2xl hidden max-md:block" onClick={showDrawer} /> */}
          <Button />
        </div>
      </div>
      {/* <Drawer
        style={{ backgroundColor: "#030014" }}
        title=""
        className="bg-block03"
        placement="top"
        onClose={onClose}
        open={open}
        closable={false}
      >
        <div className="text-right mb-8"><CloseOutlined className="text-white text-3xl" onClick={onClose} /></div>
        <NavLink className="mb-8 text-white" href={"https://www.baidu.com"}  >{t('header.product')}</NavLink>
        <NavLink className="mb-8 text-white" href={""} >{t('header.ourStory')}</NavLink>
        <NavLink className="mb-8 text-white" href={""} >{t('header.careers')}</NavLink>
      </Drawer> */}
    </header>
  )
}

export default Header