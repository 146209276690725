import { motion } from "framer-motion"
import edgeimg from '@/image/edgeimg.png'
import { useTranslation } from "react-i18next"
const Edge = () => {
    const { t } = useTranslation()
    const data = [
        { title: t('home.edgeItem1Title'), text: t('home.edgeItem1Text') },
        { title: t('home.edgeItem2Title'), text: t('home.edgeItem2Text') },
        { title: t('home.edgeItem3Title'), text: t('home.edgeItem3Text') },
    ]
    return (
        <div className="max-w-7xl mx-auto box-border pt-41.25 pb-54 max-md:pb-32">
            <div className="text-center text-14 font-semibold pb-27 mb-43.25">
                <span className=" bg-custom-gradient-3  bg-clip-text text-transparent">{t('home.edgeTitle')}</span>
            </div>
            <div
                className="flex items-center justify-center pt-16.25 max-md:flex-col-reverse "
            >
                <div className="max-w-119.5 max-md:max-w-none max-md:w-[90%]">
                    {data.map((item, index) => (
                        <motion.div key={index}
                            initial={{ opacity: 0, y: index * 20 }}
                            whileInView={{ opacity: 1, y: 0 }} // 当元素进入视口时：完全显示，位置复位
                            viewport={{ once: true }} // 仅在首次进入视口时触发
                            transition={{ delay: index * 0.3, duration: 0.5 }}
                            className={`mb-8 pb-8   ${index !== data.length - 1 ? 'border-b' : ''} border-rgb1`}
                        >
                            <div className="text-10 text-white font-medium text10 pb-4 ">{item.title}</div>
                            <p className="text-rgba8 text-lg leading-[-0.2px]">{item.text}</p>
                        </motion.div>
                    ))}
                </div>
                <img src={edgeimg} className="max-w-124.25 ml-54.25 max-md:ml-0 max-md:mb-16" alt="" />
            </div>
        </div>
    )
}

export default Edge