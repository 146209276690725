import { motion } from "framer-motion"
import light from '@/image/light.png'
import yuan from '@/image/yuan.png'
import fun1 from '@/image/fun1.png'
import fun2 from '@/image/fun2.png'
import fun3 from '@/image/fun3.png'
import fun4 from '@/image/fun4.png'
import { useTranslation } from "react-i18next"
const FunFte = () => {
    const { t } = useTranslation()
    const data = [
        { img: fun1, title: t('home.dataAnalysis'), text: t('home.dataAnalysisText') },
        { img: fun2, title: t('home.optionalList'), text: t('home.optionalListText') },
        { img: fun3, title: t('home.tradingAlerts'), text: t('home.tradingAlertsText') },
        { img: fun4, title: t('home.thermalAnalysis'), text: t('home.thermalAnalysisText') }
    ]
    return (
        <div className="max-w-7xl mx-auto flex items-center flex-col">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                className="mb-44.25"
            >
                <div className="relative">
                    <img className="w-150" src={light} alt="" />
                    <div className=" absolute  -bottom-7 left-1/2  -translate-x-1/2  text-14 font-semibold bg-custom-gradient-3  bg-clip-text text-transparent">{t('home.features')}</div>
                </div>
            </motion.div>
            <div className="flex items-center flex-col pb-[3.72656rem] bg-funbg bg-[position:50%_5.625rem] bg-[length:59.375rem_auto] bg-no-repeat max-md:bg-none">
                <motion.div
                    initial={{ opacity: 0, }}
                    whileInView={{ opacity: 1, }} // 当元素进入视口时：完全显示，位置复位
                    viewport={{ once: true }} // 仅在首次进入视口时触发
                    transition={{ delay: 0.3, duration: 0.5 }}
                    className="max-w-117 box-border pt-10 px-9.25 pb-4.875 flex items-center flex-col bg-custom-gradient-4 mb-12"
                >
                    <img className="w-10 mb-6" src={yuan} alt="" />
                    <div className="text-white text-xl font-semibold pb-2">{t('home.marketUpdate')}</div>
                    <p className="text-rgba7 font-light leading-6 text-center">{t('home.marketUpdateText')}</p>
                </motion.div>
                <div className="flex flex-wrap items-start justify-center">
                    {data.map((item, index) => (
                        <motion.div key={index}
                            initial={index % 2 ? { opacity: 0, x: 20 } : { opacity: 0, x: -20 }}
                            whileInView={index % 2 ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }} // 当元素进入视口时：完全显示，位置复位
                            viewport={{ once: true }} // 仅在首次进入视口时触发
                            transition={{ delay: 0.3, duration: 0.5 }}
                            className="max-w-117 box-border pt-10 px-8 pb-4.875 flex items-center flex-col max-md:bg-custom-gradient-4 max-md:mb-12"
                        >
                            <img className="w-10 mb-6" src={item.img} alt="" />
                            <div className="text-white text-xl font-semibold pb-2">{item.title}</div>
                            <p className="text-rgba7 font-light leading-6 text-center">{item.text}</p>
                        </motion.div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default FunFte