import Edge from "./components/Edge"
import Experience from "./components/Experience"
import FunFte from "./components/FunFte"
import LunchApp from "./components/LunchApp"
import Roadmap from "./components/Roadmap"
import Solution from "./components/Solution"
import Why from "./components/Why"

const Home = () => {
  return <>
    <div className="relative overflow-hidden">
      <div className="absolute  top-0 -left-40 w-[71.08275rem] h-[71.24725rem] bg-middleleft bg-no-repeat bg-[length:71.08275rem_71.24725rem]  max-md:bg-[length:40.08275rem_40.24725rem] " />
      <div className="absolute top-0 -right-40 w-[70.4424rem] h-[70.6054rem] bg-middleright bg-no-repeat bg-[length:70.4424rem_70.6054rem] max-md:-right-96 max-md:bg-[length:50.4424rem_50.6054rem]" />
      <div className="bg-lunchAppCenbg bg-no-repeat bg-[position:50%_55%] bg-[length:38.375rem_43.1875rem] mb-[3.234375rem] max-md:bg-[length:32.375rem_38.1875rem] max-md:bg-[position:50%_35%]">
        <LunchApp />
      </div>
      <div className="bg-bgside w-[17.615rem] h-[21.4683rem] bg-[length:17.615rem_21.4683rem] bg-no-repeat absolute top-[68.75rem] right-0 -z-10" />
      <FunFte />
      <div className="bg-whybg bg-no-repeat bg-[position:-11.875rem_0] bg-[length:77.3125rem_60.75rem]">
        <Why />
      </div>
      <Solution />
      <Edge />
      <Roadmap />
      <div className="pt-51.25 relative pb-57.375">
        <div className="absolute top-0 left-0  w-full h-full bg-experience2 bg-no-repeat bg-cover bg-center -z-20  max-md:bg-[position:50%_85%] max-md:bg-[length:100%_120%] " />
        <Experience />
      </div>
    </div>

  </>
}

export default Home