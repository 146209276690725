import { motion } from "framer-motion"
import right from '@/image/right.png'
import lock from '@/image/lock.png'
import cp from '@/image/cp.png'
import { useTranslation } from "react-i18next"
const LunchApp = () => {
    const { t } = useTranslation()
    const data = [
        { img: cp, title: t('home.platformTitle1'), text: t('home.platformText1'), className: "w-102.2", },
        { img: lock, title: t('home.platformTitle1'), text: t('home.platformText2'), className: "w-102.2" }
    ]
    return (
        <div className="max-w-7xl mx-auto flex items-center flex-col">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                className="box-border pt-37.5 pb-84 text-white flex items-center flex-col max-md:pb-44 max-md:px-5 max-md:text-center"
            >
                <div className=" font-bold text-16 pb-6 text-center max-md:text-10 max-md:relative max-md:z-10">{t('home.title')}</div>
                <div className="max-w-140 text-center mx-auto text-xl pb-11 text-EA font-light max-md:relative max-md:z-10">
                    <span className="text-F9">SMDX</span>
                    <span>{t('home.subtitle')}</span>
                </div>
                <div className="flex items-center relative z-10">
                    <div
                        className="cursor-pointer flex items-center rounded-9.75 px-5 py-3.25 mr-6
                    bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 transition">
                        <span className="mr-1 text-base font-medium">{t('home.launchApp')}</span>
                        <img className="w-3.5" src={right} alt="" />
                    </div>
                    <a href="#roadmap">
                        <div className="flex items-center cursor-pointer">
                            <span className="mr-1">{t('home.roadmap')}</span>
                            <img className="w-3.5" src={right} alt="" />
                        </div>
                    </a>
                </div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, }}
                whileInView={{ opacity: 1, }} // 当元素进入视口时：完全显示，位置复位
                viewport={{ once: true }} // 仅在首次进入视口时触发
                transition={{ delay: 0.3, duration: 0.8 }}
                className="rounded-2.5 max-w-304 box-border pt-13.25 pb-17.25 px-20 bg-rgb72 max-md:px-5"
            >
                <div className="text-white font-semibold text-14 text-center mb-8.25  max-md:text-10">
                    <span>{t('home.platform1')}</span>
                    <span className="bg-custom-gradient bg-clip-text text-transparent">
                        {t('home.platform2')}
                    </span>
                    <span>{t('home.platform3')}</span>
                </div>

                <div className="px-20.75">
                    <div className="flex items-start relative max-md:flex-col max-md:items-center">
                        <div className="absolute left-0 top-5 max-md:hidden ">
                            <div className="h-36 w-0.25 bg-custom-gradient-2-360 relative" >
                                <div className="w-0.25 h-4 absolute bg-rgb24 top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2" />
                            </div>
                        </div>
                        {data.map((item, index) => (
                            <div key={index} className="w-calc1.5 flex items-center  max-md:mb-8 ">
                                <div className="w-full flex items-center flex-col">
                                    <img className="w-10" src={item.img} alt="" />
                                    <p className="font-semibold text-white pt-3 pb-2 ">{item.title}</p>
                                    <p className={`text-rgba9 leading-6 text-center font-light ${item.className}`}
                                    >{item.text}</p>
                                </div>
                            </div>

                        ))}
                        <div className={`h-40 w-0.25 bg-custom-gradient-2-0 absolute  left-1/2 -translate-x-1/2  top-1/2 -translate-y-1/2  max-md:hidden`} >
                            <div className="w-0.25 h-4 absolute bg-rgb24 top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2" />
                        </div>
                        <div className="h-56 w-0.25 bg-custom-gradient-2-360 absolute right-0 -top-8 max-md:hidden" />
                    </div>
                </div>
            </motion.div>
        </div>

    )
}

export default LunchApp