import { useNavigate } from "react-router-dom";
import logo from "@/image/logo.png";
export const Logo = () => {
    const navigate = useNavigate()

    return (
        <div onClick={() => (navigate('/'))} className="cursor-pointer">
            <img className="w-30.3045" src={logo} alt="" />
        </div>
    );
};