import './style/index.css'
import AppRouter from '@/router'
import { ToastContainer } from 'react-toastify'
function App() {
  return (
    <div>
      <AppRouter />
      <ToastContainer />

    </div>
  );
}

export default App;
