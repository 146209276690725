import logo from '@/image/logo.png'
import x from '@/image/x.png'
import telegram from '@/image/telegram.png'
import githup from '@/image/githup.png'
import discord from '@/image/discord.png'
import { Dropdown, MenuProps, Space } from 'antd'
import languageimg from '@/image/language.png'
import bottom from '@/image/bottom.png'
import { useTranslation } from 'react-i18next'
import useLanguage from '@/store/useLanguage'

const dataImg = [{
  img: x,
  href: ''

}, {
  img: discord,
  href: ''

}, {
  img: githup,
  href: ''

}, {
  img: telegram,
  href: ''

}]
const items: MenuProps['items'] = [
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'vt',
    label: 'Việt nam',
  },
  {
    key: 'zh',
    label: '简体中文',
  }
];
const Foot = () => {
  const { i18n, t } = useTranslation(); // 获取国际化相关函数
  const { setLanguage } = useLanguage(); // 获取设置语言的函数
  const data = [
    { title: t('foot.helpCenter'), text: [{ name: t('foot.faq'), href: '' }, { name: t('foot.userGuide'), href: '' }, { name: t('foot.technicalSupport'), href: '' }] },
    { title: t('foot.legal'), text: [{ name: t('foot.privacyPolicy'), href: '' }, { name: t('foot.userAgreement'), href: '' }] }
  ]
  // 切换语言
  const changeLanguage: MenuProps["onClick"] = ({ key }) => {
    i18n.changeLanguage(key); // 切换语言
    setLanguage(key); // 设置语言到状态
  };
  return (
    <div className="flex items-center justify-between max-w-7xl  relative mx-auto box-border
    pt-20 pb-42.5 pl-42.5 max-md:flex-col max-md:px-0">
      <div className=' max-md:mb-12 max-md:flex max-md:justify-center max-md:items-center max-md:flex-col '>
        <img className='w-38.25 mb-7.5' src={logo} alt="" />
        <div className='flex items-center mb-7.5 max-md:justify-center max-md:ml-2.5'>
          {dataImg.map((item, index) => (
            <a href='javascript&:;' key={index} className='w-6.5 h-6.5 flex items-center justify-center bg-84 rounded-full mr-2.5 hover:bg-white'><img className='w-4' src={item.img} alt="" /></a>
          ))}
        </div>
        <div className='text-84'>{t('foot.reserved')}</div>
      </div>
      <div className=' max-md:w-[90%] max-md:mx-auto '>
        <div className='flex items-start  max-md:justify-between'>
          {data.map((item, index) => (
            <div key={index} className={` ${index !== data.length - 1 ? 'mr-29.1325' : 'mr-16.95'} max-md:m-0`}>
              <div className='text-white pb-3.25'>{item.title}</div>
              <div className="text-84">
                {item.text.map((v, i) => (
                  <a key={i} href={v.href} className='mb-3.25 block hover:text-white whitespace-normal'>{v.name}</a>
                ))}
              </div>
            </div>
          ))}
          <Dropdown menu={{ items, onClick: changeLanguage }}
            className='text-white cursor-pointer'
            trigger={['click']}
          >
            <div onClick={(e) => e.preventDefault()}>
              <Space className='flex items-center'>
                <img src={languageimg} className='w-5' alt="" />
                <div className='text-84'>
                  {items && (items as any).filter((item: any) => item.key === i18n.language)[0].label}
                </div>
                <img src={bottom} className='w-4.25' alt="" />
              </Space>
            </div>
          </Dropdown>

        </div>

      </div>
    </div>
  )
}

export default Foot