import { useTranslation } from "react-i18next"

const Why = () => {
    const { t } = useTranslation()
    const data = [
        { title: t('home.lowFeesTitle'), text: t('home.lowFeesText') },
        { title: t('home.realTimeDataTitle'), text: t('home.realTimeDataText') },
        { title: t('home.simpleDesignTitle'), text: t('home.simpleDesignText') },
        { title: t('home.crossPlatformTitle'), text: t('home.crossPlatformText') },
        { title: t('home.openApiTitle'), text: t('home.openApiText') },
    ]
    return (
        <div className="flex items-start max-w-7xl mx-auto justify-center box-border pt-52.25 pb-54.75 max-md:flex-col max-md:items-center">
            <div className="bg-custom-gradient-5 bg-clip-text text-transparent text-14 font-semibold mr-75.75 max-md:mr-0 max-md:text-center max-md:pb-14">
                <div>{t('home.whyChoose')}</div>
                <div>SMDX?</div>
            </div>
            <div>
                {data.map((item, index) => (
                    <div key={index}>
                        <div className={`cursor-pointer  w-127.5 text-white text-2xl py-7.5 ${index ? '' : 'pt-0'} ${index !== data.length - 1 ? 'border-b' : 'pb-0 border-none'}   border-rgb1 group`}>
                            <div className="flex  items-center">
                                <span className="mr-3 text-xl text-95">{'0' + (index + 1)}</span>
                                <p >{item.title}</p>
                            </div>
                            <p className="text-base text-rgba7 pt-2 max-h-0 overflow-hidden group-hover:max-h-36 transform  duration-500">{item.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Why