import { motion } from "framer-motion"
import solution1 from '@/image/solution1.png'
import solution2 from '@/image/solution2.png'
import solution3 from '@/image/solution3.png'
import solution4 from '@/image/solution4.png'
import { useTranslation } from "react-i18next"

const Solution = () => {
    const { t } = useTranslation()
    const data1 = [
        { img: solution1, title: t('home.decentralizedTradingTitle'), text1: t('home.decentralizedTradingText1'), text2: t('home.decentralizedTradingText2'), imgWidth: "8.01375rem" },
        { img: solution2, title: t('home.userExperienceTitle'), text1: t('home.userExperienceText1'), text2: t('home.userExperienceText2'), imgWidth: "8.01375rem" },
    ]
    return (
        <div className="max-w-7xl mx-auto box-border pt-27.5 pb-12.75">
            <div className="text-center text-14 font-semibold pb-27">
                <span className=" bg-custom-gradient-3  bg-clip-text text-transparent">{t('home.solutionTitle')}</span>
            </div>
            <div className="bg-solutionbg bg-[position:16.25rem_9.375rem] bg-no-repeat bg-[length:43.6235625rem_43.7245rem]">
                <div
                    className="flex items-stretch  justify-center mb-8 max-md:mb-0 max-md:flex-col max-md:items-center"
                >
                    {data1.map((item, index) => (
                        <motion.div
                            initial={{ opacity: 0, x: -100, }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.3, duration: 0.8 }}
                            className={`max-w-96.25 pt-12.25 px-8 pb-11 flex items-center  flex-col  rounded-2xl bg-rgb5 shadow-boxShadow1   ${index ? "" : "mr-8"} max-md:mr-0 max-md:mb-16 max-md:max-w-none max-md:w-[90%]`} key={index}>
                            <img style={{ maxWidth: item.imgWidth }} className="mb-6.195" src={item.img} alt="" />
                            <div className="text-white text-xl font-medium pb-5">{item.title}</div>
                            <p className="text-base text-rgba9-2 leading-6 text-center pb-4">{item.text1}</p>
                            <p className="text-base text-rgba9-2 leading-6 text-center">{item.text2}</p>
                        </motion.div>
                    ))}
                </div>
                <div
                    className="flex  items-stretch justify-center max-md:flex-col max-md:items-center max-md:mr-0 ">
                    <motion.div
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.3, duration: 0.8 }}
                        className={`max-w-140 pt-9.75  pb-11 flex items-center  flex-col  rounded-2xl bg-rgb5 shadow-boxShadow1  mr-8 max-md:mr-0 max-md:max-w-none max-md:w-[90%] max-md:mb-16 `}>
                        <div className="w-full box-border px-15">
                            <img className="mb-6.195" src={solution3} alt="" />
                        </div>
                        <div className="text-white text-xl font-medium pb-5">{t('home.crossChainTradingTitle')}</div>
                        <p className=" px-8 text-base text-rgba9-2 leading-6 text-center pb-4">{t('home.crossChainTradingText1')}</p>
                        <p className=" px-8 text-base text-rgba9-2 leading-6 text-center">{t('home.crossChainTradingText2')}</p>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, x: 100, }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.3, duration: 0.8 }}
                        className={`max-w-140   pb-11 flex items-center  flex-col  rounded-2xl bg-rgb5 shadow-boxShadow1 max-md:mr-0 max-md:max-w-none max-md:w-[90%]`}>
                        <div className="w-full box-border">
                            <img src={solution4} alt="" />
                        </div>
                        <div className="text-white text-xl font-medium pb-5">{t('home.marketTrendsTitle')}</div>
                        <p className=" px-8 text-base text-rgba9-2 leading-6 text-center pb-4">{t('home.marketTrendsText1')}</p>
                        <p className=" px-8 text-base text-rgba9-2 leading-6 text-center">{t('home.marketTrendsText2')}</p>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default Solution