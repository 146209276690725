import { ConfigProvider, Steps } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
const Roadmap = () => {
    const { t } = useTranslation()
    const [data2, setData2] = useState<{ title: string, content: string[] }[]>([])
    const data = useMemo(() => [
        {
            title: "",
            content: [],
            x: "-34.41rem",
            y: "18.13rem",
            tx: "0rem",
            ty: "0rem",
            top: -1,
            left: -0,
            edg: -0,
            tleY: "0",
            tleX: "0",
            isShow: false,
        },
        {
            title: "Q1 2024.12",
            content: [
                t('home.roadmapItem1Content1'),
                t('home.roadmapItem1Content2'),
                t('home.roadmapItem1Content3'),
            ],
            tWidth: "25.63rem",
            x: "-18.78rem",
            y: "18.13rem",
            tx: "-22.5rem",
            ty: "-16.38rem",
            tleY: "1.13rem",
            tleX: "-3.75rem",
            top: -4,
            left: -2.5,
            edg: -0.5,
        },
        {
            title: "Q2 2025.01",
            content: [
                t('home.roadmapItem2Content1'),
                t('home.roadmapItem2Content2'),
                t('home.roadmapItem2Content3'),
            ],
            tWidth: "26.56rem",
            x: "-12.53rem",
            y: "9.38rem",
            top: 0,
            left: 0,
            tleY: "1.13rem",
            tleX: "0",
            tx: "0rem",
            ty: "-9.38rem",
            edg: 0,
        },
        {
            title: "Q3 2025.02",
            content: [
                t('home.roadmapItem3Content1'),
                t('home.roadmapItem3Content2'),
                t('home.roadmapItem3Content3'),
                t('home.roadmapItem3Content4'),
            ],
            tWidth: "28.81rem",
            x: "18.75rem",
            y: "9.38rem",
            top: -6,
            left: -8,
            tleY: "1.13rem",
            tleX: "-3.75rem",
            tx: "-14.38rem",
            ty: "3.75rem",
            edg: 0,
        },
        {
            title: "Q4 2025.03",
            content: [
                t('home.roadmapItem4Content1'),
                t('home.roadmapItem4Content2'),
                t('home.roadmapItem4Content3'),
                t('home.roadmapItem4Content4'),
            ],
            tWidth: "26.5rem",
            x: "15rem",
            y: "0rem",
            top: 0,
            left: 0,
            tleY: "1.13rem",
            tleX: "3.75rem",
            tx: "0rem",
            ty: "-12.5rem",
            edg: 0,
            f: true,
        },
        {
            title: "",
            content: [],
            x: "31.28rem",
            y: "0rem",
            tx: "0rem",
            ty: "0rem",
            tleY: "0",
            tleX: "0",
            top: -1,
            left: -0,
            edg: -0,
            isShow: false,
        },
    ], [t])
    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        const arr = data.filter(item => {
            if (item.title && item.content.length) {
                return {
                    title: item.title,
                    content: item.content,
                }
            }
        })
        setData2(arr);
    }, [data])
    return (
        <div className="max-w-7xl mx-auto box-border pt-105.25 pb-4.0125 bg-roadmapbg bg-no-repeat
        bg-[position:1.25rem_0rem] bg-[length:64rem_37.5rem] max-md:bg-[length:80%_auto] max-md:bg-[position:0%_0rem] max-md:pt-64
        ">
            <div id="roadmap" className="text-center text-14 font-semibold  mb-76.2 max-878:mb-40 ">
                <span className=" bg-custom-gradient-3   bg-clip-text text-transparent">{t('home.roadmapTitle')}</span>
            </div>
            <div className="relative  h-[37.625rem] flex  justify-center  max-878:hidden">
                {data.map((item, index) => (
                    <TimelineItem
                        key={index + item.title}
                        index={index}
                        x={item.x}
                        y={item.y}
                        top={item.top}
                        left={item.left}
                        tx={item.tx}
                        ty={item.ty}
                        title={item.title}
                        edg={item.edg}
                        content={item.content}
                        isShow={item.isShow}
                        f={item.f}
                        tleY={item.tleY}
                        tleX={item.tleX}
                        tWidth={item.tWidth}
                    />
                ))}

            </div>
            <div className="w-[80%] mx-auto hidden text-white max-878:block">
                <ConfigProvider
                    theme={{
                        components: {
                            Steps: {
                                colorText: "rgba(255, 255, 255, 0.60)",
                                colorTextDescription: "#B6B6B6",
                                descriptionMaxWidth: 500,
                                colorPrimary: "#8D50FF",
                            }
                        },
                    }}
                >
                    <Steps
                        progressDot
                        current={10}
                        direction="vertical"
                        items={data2.map((item) => ({
                            title: item.title,
                            description: <ul
                                className=" list-none text-rgba9 text-base box-border p-6  rounded-lg "
                            >
                                {item.content.map((v, i) => (
                                    <li className="flex items-baseline " key={i}>
                                        <div className="rounded-full w-1 h-1  bg-[#B6B6B6] mr-[6px] relative -top-1" />
                                        {v}
                                    </li>
                                ))}
                            </ul>
                        }))}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default Roadmap

const TimelineItem = ({ title, content, index, x, y, top, left, tx, ty, edg, isShow = true, f = false, tleY, tleX, tWidth }: { tWidth?: string, tleY: string, tleX: string, f?: boolean, isShow?: boolean, title: string, content: string[], top: number, left: number, index: number, x: string, y: string, tx: string, ty: string, edg: number }) => {
    const dotRef = useRef(null);
    const lineRef = useRef(null);

    useEffect(() => {
        const drawLine = () => {
            if (dotRef.current && lineRef.current) {
                const dot: any = dotRef.current;
                const nextDot = document.querySelectorAll(".dot")[index + 1];
                if (nextDot) {
                    const dotL = dot.getBoundingClientRect();
                    const nextDotL = nextDot.getBoundingClientRect();
                    const startX = dotL.left + dotL.width / 2;
                    const startY = dotL.top + dotL.height / 2;
                    const endX = nextDotL.left + nextDotL.width / 2;
                    const endY = nextDotL.top + nextDotL.height / 2;
                    const length = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
                    const angle =
                        Math.atan2(endY - startY, endX - startX) * (180 / Math.PI);
                    (lineRef as any).current.style.width = `${length - dotL.width}px`;
                    (lineRef as any).current.style.transform = `rotate(${angle + edg}deg)`;
                    (lineRef as any).current.style.top = `${top}px`;
                    (lineRef as any).current.style.left = `${(dotL.width / 2) + left}px`;
                }
            }
        };

        drawLine();

        window.addEventListener("resize", drawLine);

        return () => window.removeEventListener("resize", drawLine);
    }, [edg, index, left, top]);

    return (
        <div className={`relative `} style={{ top: y, left: x }}>
            <div
                ref={dotRef}
                className={` group
                     dot ${isShow ? "bg-[#56BCFD] shadow-[0_0_5px_5px_rgba(74,168,255,0.5)] " : "bg-transparent"}
                 cursor-pointer w-2.5 h-2.5    rounded-full absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2
                    `}
            >
                <p style={{ top: tleY, left: tleX }} className="absolute whitespace-nowrap  text-rgba6 text-2xl font-semibold group-hover:text-white">{title}</p>
                {!!content.length && (
                    <ul
                        style={{ top: ty, left: tx, width: tWidth }}
                        className=" list-none absolute  cursor-pointer  text-rgba9  box-border p-6  group-hover:bg-[#17151F] rounded-lg "
                    >
                        {content.map((item, index) => (
                            <li className="flex items-baseline " key={index}>
                                <div className="rounded-full w-1 h-1  bg-[#B6B6B6] mr-[6px] relative -top-1" />
                                {item}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div
                ref={lineRef}
                className={`absolute h-[2px]  ${isShow ? f ? 'bg-gradient-to-r from-[#8D50FF] to-transparent' : ' bg-[#8D50FF] ' : f ? 'bg-gradient-to-r from-[#8D50FF] to-transparent' : 'bg-gradient-to-r from-transparent to-[#8D50FF]'}    origin-top-left `}
            ></div>

        </div>
    );
};