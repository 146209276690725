import right2 from '@/image/right2.png'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
const Experience = () => {
    const { t } = useTranslation()
    return (
        <div className=' w-full bg-experience1 bg-no-repeat bg-[length:100%_14.688rem] bg-[position:0rem_0.125rem]'>
            <motion.div
                initial={{ opacity: 0, }}
                whileInView={{ opacity: 1, }} // 当元素进入视口时：完全显示，位置复位
                viewport={{ once: true }} // 仅在首次进入视口时触发
                transition={{ delay: 0.3, duration: 0.5 }}
                className='flex items-center flex-col justify-center'
            >
                <div className="text-white text-11.25 font-semibold pb-5">{t('home.experienceTitle')}</div>
                <p className="text-E5 text-xl mb-13.75 max-md:w-[90%] max-md:text-center">{t('home.experienceSubtitle')}</p>
                <div className=' box-border rounded-11.75 border-rgb3 border flex items-center justify-center cursor-pointer'>
                    <span className='ml-8 mr-4.5 text-F9F text-xl font-medium'>{t('home.experienceButtonText')} SMDX</span>
                    <div className=' my-1 mr-1 rounded-full w-15.5 h-15.5 bg-custom-gradient-6 flex items-center justify-center'>
                        <img className='w-6' src={right2} alt="" />
                    </div>
                </div>
            </motion.div>

        </div>
    )
}

export default Experience
