import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { ConfigProvider, Drawer, Input } from 'antd';
import { useState } from 'react';

export const Button = () => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorBgContainer: "#31363F", // 组件的容器背景色，例如：默认按钮、输入框等
                        activeBorderColor: "transparency", // 激活态边框色
                        hoverBorderColor: "transparency", // 悬浮态边框色
                        colorBorder: "transparency",  // 默认使用的边框颜色
                        activeShadow: "transparency", // 激活态阴影,
                        colorText: '#fff',
                        colorTextPlaceholder: "transparency",
                        lineWidth: 0
                    },
                    Drawer: {
                        colorBgElevated: "#000000"
                    }
                },
            }}
        >
            <div className='max-md:hidden '>
                <Input prefix={<SearchOutlined className='text-9c' />
                } className='placeholder ' style={{ borderRadius: "2rem", }}
                    placeholder={`Search (⌘K)`} />
            </div>
            <SearchOutlined onClick={showDrawer} className='text-9c text-2xl hidden max-md:block ' />
            <Drawer
                placement={'right'}
                closable={false}
                onClose={onClose}
                open={open}
                key={'right'}
            >
                <div className='flex'>
                    <Input prefix={<SearchOutlined className='text-9c' />
                    } className='w-full' style={{ borderRadius: "2rem", }}
                        placeholder={`Search (⌘K)`} />
                    <CloseOutlined className="text-9c text-3xl ml-3" onClick={onClose} />
                </div>
            </Drawer>
        </ConfigProvider>
    );
};